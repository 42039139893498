import request from "../utils/request";

export function get_message_list(data: JSON){
    return request({
        url: "/getMessageList",
        method: "post",
        data: data
    });
}

export function get_service_list(data: JSON){
    return request({
        url: "/getServiceList",
        method: "post",
        data: data
    });
}

export function get_message_detail(data: JSON){
    return request({
        url: "/getMessageDetail",
        method: "post",
        data: data
    });
}

export function save_message_detail(data: JSON){
    return request({
        url: "/saveMessageDetail",
        method: "post",
        data: data
    });
}

export function export_message_list(data: JSON){
    return request({
        url: "/exportMessageList",
        method: "post",
        data: data
    });
}

export function get_select_faq_list(data: JSON){
    return request({
        url: "/getSelectFAQList",
        method: "post",
        data: data
    });
}

export function get_current_user_option(data: JSON){
    return request({
        url: "/getCurrentUserOption",
        method: "post",
        data: data
    });
}
